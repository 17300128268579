<template>
  <div class="st-main st-base-bg">
    <div class="user-wrapper">
      <el-container>
        <el-aside class="aside-wrapper">
          <div class="user-info">
            <div>
              <div class="icon">
                <img src="../../../assets/images/base-user-icon.png" alt="" />
              </div>
              <h4>{{ this.userInfo.name || '暂无昵称' }}</h4>
            </div>
          </div>
          <ul class="menu">
            <li
              v-for="item in menus"
              :key="item.index"
              class="active">
              <h4>{{ item.name }}</h4>
              <i class="el-icon-arrow-right"></i>
            </li>
          </ul>
        </el-aside>
        <el-main class="main-wrapper">
          <router-view />
        </el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: '',
  data () {
    return {
      menus: [
        {
          index: 1,
          name: '我的申请'
        }
      ]
    }
  },
  computed: {
    ...mapState(['userInfo'])
  }
}
</script>

<style lang="less" scoped>
  @import "./index";
</style>
